import { Header } from './header';
import { MainMenu } from './menu';

// GLOBAL
const menu = new MainMenu({ id: 'mainMenu' });
new Header({
	id: 'header',
	threshold: 0,
	burgerId: 'burger',
	toggleMenu: menu.toggle,
	invertedSelector: '.invertedHeader',
	detectorSelector: '#mainLogo svg',
});

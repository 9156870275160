interface HeaderProps {
	id: string;
	burgerId: string;
	threshold: number;
	detectorSelector: string;
	invertedSelector: string;
	toggleMenu: () => void;
}

export class Header {
	header: HTMLElement | null;
	burger: HTMLElement | null;
	threshold: number;
	invertedSections: NodeListOf<HTMLElement>;
	observer?: IntersectionObserver;
	detector: HTMLElement;

	constructor({
		id,
		threshold,
		burgerId,
		toggleMenu,
		invertedSelector,
		detectorSelector,
	}: HeaderProps) {
		this.threshold = threshold;
		this.header = document.getElementById(id);
		this.burger = document.getElementById(burgerId);
		this.invertedSections = document.querySelectorAll(invertedSelector);
		this.detector = document.querySelector(detectorSelector) as HTMLElement;

		this.burger?.addEventListener('click', toggleMenu);
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.resetObserver);

		this.onScroll();
		this.resetObserver();
	}

	private resetObserver = () => {
		this.observer?.disconnect();

        // const { top, height } = this.detector.getBoundingClientRect();
		this.observer = new IntersectionObserver(this.observerCallback, {
			rootMargin: `-${top}px 0px -${window.innerHeight - 20 - 20}px 0px`,
		});

		this.invertedSections.forEach((el) => this.observer?.observe(el));
	};

	private observerCallback = (entries: IntersectionObserverEntry[]) => {
		let inverted = false;
		entries.forEach((entry) => {
			if (entry.isIntersecting) inverted = true;
		});
		if (inverted) this.header?.classList.add('inverted');
		else this.header?.classList.remove('inverted');
	};

	private onScroll = () => {
		if (window.scrollY > this.threshold) this.header?.classList.add('small');
		else this.header?.classList.remove('small');
	};
}

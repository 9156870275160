type MainMenuProps = { id: string };

export class MainMenu {
	menu: HTMLElement | null;
	level1Wrapper?: HTMLElement | null;
	level1Links?: NodeListOf<HTMLElement>;
	level2Menus?: NodeListOf<HTMLElement>;
	backButtons?: NodeListOf<HTMLElement>;

	constructor({ id }: MainMenuProps) {
		this.menu = document.getElementById(id);
		this.level1Wrapper = this.menu?.querySelector('.level1Wrapper');
		this.level1Links = this.menu?.querySelectorAll('.level1Link');
		this.level2Menus = this.menu?.querySelectorAll('.level2Menu');
		this.backButtons = this.menu?.querySelectorAll('.backToLevel1');

		this.bind();
	}
	
	private bind() {
		this.level1Links?.forEach((btn, i) => {
			const id = btn.getAttribute('data-id');

			if (id) {
				btn.onmouseover = () => {
					this.level1Links?.forEach((link, o) => {
						this.level1Wrapper?.classList.add('hidden');
						if (i === o) link.classList.add('active');
						else link.classList.remove('active');
					});

					this.level2Menus?.forEach((level2Menu) => {
						if (level2Menu.id === 'level2Menu-' + id) {
							level2Menu.classList.remove('hidden');
						} else {
							level2Menu.classList.add('hidden');
						}
					});
				};
			} else {
				btn.onmouseover = () => this.closeAll();
			}
		});

		this.backButtons?.forEach((btn) => {
			btn.onclick = () => this.closeAll();
		});
	}

	public toggle = () => {
		document.body.classList.toggle('menuOpen');
		if (document.body.classList.contains('menuOpen')) {
			this.closeAll();
		}
	};

	private closeAll() {
		this.menu?.scrollTo(0, 0);
		this.level1Links?.forEach((btn) => btn.classList.remove('active'));
		this.level2Menus?.forEach((subMenu) => subMenu.classList.add('hidden'));
		this.level1Wrapper?.classList.remove('hidden');
	}
}
